<template>
  <div
    v-if="isVisible"
    class="property-duplicate-button"
    :style="{
      maxWidth: `${maxWidth}px`,
    }"
  >
    <v-btn
      text
      class="property-duplicate-button__btn"
      color="blue"
      :disabled="loading"
      :loading="loading"
      @click="toggle"
    >
      Duplicate
    </v-btn>

    <ConfirmModal
      :value="showModal"
      :title="confirmationTitle"
      :message="confirmationMessage"
      :confirm-color="confirmColor"
      :confirm-text="confirmText"
      :cancel-color="cancelColor"
      :cancel-text="cancelText"
      :loading="loading"
      @cancel="close"
      @confirm="duplicate"
      @input="toggle"
    ></ConfirmModal>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Property Duplicate Button
 * ==================================================================================
 **/
import { mapActions } from 'vuex'
import { Model } from 'vue-api-query'
import { capitalize } from '@/utils/helpers'
import ConfirmModal from '@/components/modals/ConfirmModal'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'

export default {
  components: {
    ConfirmModal,
  },
  mixins: [SnackbarMixin, ErrorHandlerMixin],
  props: {
    property: {
      type: Model,
      default: () => {
        return null
      },
    },

    title: {
      type: String,
      default: 'Delete?',
    },

    cancelColor: {
      type: String,
      default: 'red',
    },

    cancelText: {
      type: String,
      default: 'Cancel',
    },

    confirmColor: {
      type: String,
      default: 'green',
    },

    confirmText: {
      type: String,
      default: 'Confirm',
    },

    maxWidth: {
      type: Number,
      default: 400,
    },
  },

  data() {
    return {
      showModal: false,
      loading: false,
    }
  },

  computed: {
    nameCapitalized() {
      return this.property ? capitalize(this.property.title) : null
    },

    confirmationTitle() {
      return `Duplicate Property`
    },

    confirmationMessage() {
      return `Are you sure you want to duplicate the ${this.nameCapitalized} property?`
    },

    isVisible() {
      return !!this.property
    },
  },

  methods: {
    ...mapActions({
      duplicateProperty: 'property/duplicateProperty',
    }),

    toggle(show = null) {
      this.showModal =
        show === null || typeof show !== 'boolean' ? !this.showModal : show
    },
    open() {
      this.toggle(true)
    },
    close() {
      this.toggle(false)
    },

    async duplicate() {
      if (this.loading) return
      this.loading = true

      await this.duplicateProperty({
        id: this.property.id,
      })
        .then((data) => {
          this.showSnackbar(`${this.nameCapitalized} duplicated successfully!`)
          this.close()

          this.$router.push({
            name: 'property.details',
            params: {
              id: data.id,
            },
          })
        })
        .catch((error) => {
          this.showSnackbar(this.getErrorMessage(error), false)
          this.close()
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.property-duplicate-button {
  text-align: right;

  &__btn {
    font-weight: bold;
  }
}
</style>
